var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"x-data-table-fix-action-col"},[_c('x-default-data-table',{attrs:{"x-options":_vm.tableOptions},scopedSlots:_vm._u([{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold text-no-wrap"},[_vm._v(" "+_vm._s(item.orderId)+" ")])]}},{key:"item.thumbImages",fn:function(ref){
var item = ref.item;
return [(item.productItems)?_c('div',_vm._l((item.productItems),function(productItem,index){return _c('div',{key:index},[(
              productItem.variationList &&
              productItem.variationList['_customily-thumb']
            )?_c('a',{staticClass:"pa-2",attrs:{"href":productItem.variationList['_customily-thumb'],"target":"_blank"},on:{"click":function($event){_vm.downloadVariationListItem(item, index, '_customily-thumb');
              $event.preventDefault();}}},[_c('v-img',{attrs:{"src":productItem.variationList['_customily-thumb'],"width":"50","height":"50"}})],1):_vm._e()])}),0):_vm._e()]}},{key:"item.printUrls",fn:function(ref){
              var item = ref.item;
return [(item.productItems)?_c('div',_vm._l((item.productItems),function(productItem,index){return _c('div',{key:index},[(
              productItem.variationList &&
              productItem.variationList['print_url']
            )?_c('a',{staticClass:"pa-2",attrs:{"href":productItem.variationList['print_url'],"target":"_blank","download":""},on:{"click":function($event){_vm.downloadVariationListItem(item, index, 'print_url');
              $event.preventDefault();}}},[_c('v-img',{attrs:{"src":productItem.variationList['print_url'],"width":"50"}})],1):_vm._e()])}),0):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }