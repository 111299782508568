






























































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import {
  sourceTypes,
  statuses,
  shipVendorTypes,
  paymentStatuses,
  shippingStatuses,
  fulfillmentStatuses,
} from "@/constants/orders";
import makeSelectOptions from "@common/util/makeSelectOptions";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import ProductItemsTable from "./base-orders/partials/ProductItemsTable.vue";
import merge from "@common/modules/vuetifyx/utils/merge";
import userManager from "@/services/userManager";
import downloadFile from "@/utils/downloadFile";
import openInNewTab from "@/utils/openInNewTab";

function valueItemValueInput(
  name: string,
  label: string,
  extendOptions = {},
  domain?: string
) {
  return merge.clone(
    {
      type: "XAutocomplete",
      attrs: {
        label,
        "item-value": "value",
        "item-text": "value",
        multiple: true,
        xOptions: {
          content: {
            async itemsSuggestor(search, value, limit) {
              const { items } = await coreApiClient.call(
                "valueItems",
                "findAll",
                {
                  payload: JSON.stringify({
                    limit,
                    search,
                    filters: [
                      {
                        key: "name",
                        operator: "equal_to",
                        value: name,
                      },
                      {
                        key: "value",
                        operator: "in",
                        value,
                      },
                      {
                        key: "domain",
                        operator: "equal_to",
                        value: domain,
                      },
                    ],
                  }),
                }
              );
              return items;
            },
          },
        },
      },
      rules: {
        in: {},
      },
    },
    extendOptions
  );
}

export default Vue.extend({
  props: ["status", "orderBy"],
  data() {
    const self = this;
    return {
      userManager,
      downloadFile,
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": this.orderBy || "sourceTime",
          "sort-desc": true,
        },
        content: {
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            colLength: 2,
            filters: {
              orderId: {
                attrs: {
                  label: "Order ID",
                },
                rules: {
                  equal_to: {},
                },
                ext: {
                  important: true,
                },
              },
              storeId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Store",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "stores",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "in_id",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
                ext: {
                  important: true,
                },
              },
              sourceType: {
                type: "selectMultiple",
                attrs: {
                  label: "Source Type",
                  items: makeSelectOptions(sourceTypes),
                },
                rules: {
                  in: {},
                },
              },
              originSourceId: {
                attrs: {
                  label: "Origin Source ID",
                },
                rules: {
                  match: {},
                },
                ext: {
                  important: true,
                },
              },
              "productItems.productVariantId": {
                type: "XAutocomplete",
                attrs: {
                  label: "Product Variant",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  required: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "productVariants",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "in",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
                ext: {
                  important: true,
                },
              },
              productVariationName: {
                type: "XAutocomplete",
                attrs: {
                  label: "Variation Name",
                  "item-value": "key",
                  "item-text": "key",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const items = await coreApiClient.call(
                          "valueItems",
                          "getNames",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "name",
                                  operator: "equal_to",
                                  value,
                                },
                                {
                                  key: "domain",
                                  operator: "equal_to",
                                  value: "OrderProductVariation",
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      const filters = [];
                      if (value) {
                        filters.push({
                          key: `productItems.variationList.${value}`,
                          operator: "has_property",
                        });
                      }
                      return filters;
                    },
                  },
                },
                ext: {
                  important: true,
                },
              },
              productVariationValue: {
                type: "XAutocomplete",
                attrs: {
                  label: "Variation Value",
                  "item-value": "value",
                  "item-text": "value",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit, self) {
                        const key = self.context().context().fieldData
                          ?.productVariationName?.custom?.value;
                        const { items } = await coreApiClient.call(
                          "valueItems",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "value",
                                  operator: "in",
                                  value,
                                },
                                {
                                  key: "name",
                                  operator: "equal_to",
                                  value: key,
                                },
                                {
                                  key: "domain",
                                  operator: "equal_to",
                                  value: "OrderProductVariation",
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  custom: {
                    filterMaker(value, data) {
                      const key = data.productVariationName?.custom?.value;
                      const filters = [];
                      if (key) {
                        filters.push({
                          key: `productItems.variationList.${key}`,
                          operator: "in",
                          value,
                        });
                      }
                      return filters;
                    },
                  },
                },
                ext: {
                  important: true,
                  condition(self) {
                    return self.fieldData?.productVariationName?.custom?.value;
                  },
                  key(self) {
                    return self.fieldData?.productVariationName?.custom?.value;
                  },
                },
              },
              sourceTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Source Time",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Source Time (From)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Source Time (To)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              processTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Process Time",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Process Time (From)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Process Time (To)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              exportTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Export Time",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Export Time (From)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Export Time (To)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              completeTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Complete Time",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Complete Time (From)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Complete Time (To)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              cancelTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Cancel Time",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Cancel Time (From)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Cancel Time (To)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              status: {
                type: "selectMultiple",
                attrs: {
                  label: "Status",
                  items: makeSelectOptions(statuses),
                },
                rules: {
                  in: {},
                },
              },
              processUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Process User",
                  "item-value": "_id",
                  "item-text": "email",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "users",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              exportUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Export User",
                  "item-value": "_id",
                  "item-text": "email",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "users",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              completeUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Complete User",
                  "item-value": "_id",
                  "item-text": "email",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "users",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              cancelUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Cancel User",
                  "item-value": "_id",
                  "item-text": "email",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "users",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              "customerAddress.firstName": {
                attrs: {
                  label: "Customer First Name",
                },
                rules: {
                  equal_to: {},
                },
              },
              "customerAddress.lastName": {
                attrs: {
                  label: "Customer Last Name",
                },
                rules: {
                  equal_to: {},
                },
              },
              "customerAddress.email": {
                attrs: {
                  label: "Customer Email",
                },
                rules: {
                  equal_to: {},
                },
              },
              "customerAddress.phone": {
                attrs: {
                  label: "Customer Phone",
                },
                rules: {
                  equal_to: {},
                },
              },
              "customerAddress.city": valueItemValueInput(
                "OrderAddressCity",
                "Customer City"
              ),
              "customerAddress.state": valueItemValueInput(
                "OrderAddressState",
                "Customer State"
              ),
              "customerAddress.country": valueItemValueInput(
                "OrderAddressCountry",
                "Customer Country"
              ),
              "customerAddress.postalCode": valueItemValueInput(
                "OrderAddressPostalCode",
                "Customer Postal Code"
              ),
              paymentStatus: {
                type: "selectMultiple",
                attrs: {
                  label: "Payment Status",
                  items: makeSelectOptions(paymentStatuses),
                },
                rules: {
                  in: {},
                },
              },
              shippingVendorId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Shipping Vendor",
                  "item-value": "_id",
                  "item-text": "name",
                  required: true,
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call(
                          "shippingVendors",
                          "findAll",
                          {
                            limit: -1,
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              trackingNumber: {
                attrs: {
                  label: "Tracking Number",
                },
                rules: {
                  equal_to: {},
                },
              },
              trackingNumberAlt: {
                attrs: {
                  label: "Tracking Number Alt",
                },
                rules: {
                  equal_to: {},
                },
              },
              shippingStatus: {
                type: "selectMultiple",
                attrs: {
                  label: "Shipping Status",
                  items: makeSelectOptions(shippingStatuses),
                },
                rules: {
                  in: {},
                },
              },
              "productItems.fulfillmentStatus": {
                type: "selectMultiple",
                attrs: {
                  label: "Fulfillment Status",
                  items: makeSelectOptions(fulfillmentStatuses),
                },
                rules: {
                  in: {},
                },
              },
              "productItems.fulfillmentQualityCheckFailed": {
                type: "boolean",
                attrs: {
                  label: "Fulfillment Quality Check Failed",
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          displayFields: {
            localIndex: {
              text: "No.",
              options: {
                index: true,
              },
            },
            createdTime: {
              text: "Created Time",
              sortable: true,
              hidden: true,
              options: {
                filter: "dateTime",
              },
            },
            updatedTime: {
              text: "Updated Time",
              sortable: true,
              hidden: true,
              options: {
                filter: "dateTime",
              },
            },
            orderId: {
              text: "Order ID",
            },
            store: {
              text: "Store",
              options: {
                label: true,
                subProp: "store.name",
                sortBy: "storeId",
              },
            },
            description: {
              text: "Description",
            },
            sourceType: {
              text: "Source Type",
              options: {
                label(value) {
                  return sourceTypes[value || ""] || value;
                },
              },
            },
            originSourceId: {
              text: "Origin Source ID",
            },
            sourceTime: {
              text: "Source Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            status: {
              text: "Status",
              options: {
                label(value) {
                  return statuses[value || ""] || value;
                },
              },
            },
            productVariants: {
              text: "Product Variants",
              options: {
                labels(_, item) {
                  return item.productItems
                    .map((item) => item.productVariant?.name)
                    .filter((value) => value);
                },
              },
            },
            productQuantities: {
              text: "Quantity",
              options: {
                labels(_, item) {
                  return item.productItems
                    .map((item) => item.quantity)
                    .filter((value) => value);
                },
              },
            },
            productInkColors: {
              text: "Ink Color",
              options: {
                labels(_, item) {
                  return item.productItems.map(
                    (item) => item.fulfillmentInkColor
                  );
                },
              },
            },
            productInkColorDetails: {
              text: "Ink Color Details",
              options: {
                labels(_, item) {
                  return item.productItems.map((item) => {
                    let maxColor = item?.fulfillmentInkColorParams?.maxColor;
                    const maxColorPercentage =
                      item?.fulfillmentInkColorParams?.maxColorPercentage;
                    if (!maxColor) {
                      return "";
                    }
                    if (maxColor === "000000") {
                      maxColor = "Black";
                    } else if (maxColor === "ffffff") {
                      maxColor = "White";
                    } else {
                      maxColor = `#${maxColor}`;
                    }
                    const maxColorPercentageValue = Math.floor(
                      maxColorPercentage * 100
                    );
                    return `${maxColorPercentageValue}% ${maxColor}`;
                  });
                },
              },
            },
            productPretreatment: {
              text: "Pretreatment",
              options: {
                labels(_, item) {
                  return item.productItems.map((item) =>
                    item.fulfillmentPretreatment ? "Yes" : "No"
                  );
                },
              },
            },
            thumbImages: {
              text: "Thumb Images",
            },
            printUrls: {
              text: "Print URLs",
            },
            size: {
              text: "Size",
              options: {
                labels(_, item) {
                  return item.productItems
                    .map((item) => item.variationList?.size)
                    .filter((value) => value);
                },
              },
            },
            color: {
              text: "Color",
              options: {
                labels(_, item) {
                  return item.productItems
                    .map((item) => item.variationList?.color)
                    .filter((value) => value);
                },
              },
            },
            shippingVendor: {
              text: "Shipping Vendor",
              options: {
                label: true,
                subProp: "shippingVendor.name",
                sortBy: "shippingVendorId",
              },
            },
            shippingStatus: {
              text: "Shipping Status",
              options: {
                label(value) {
                  return shippingStatuses[value || ""] || value;
                },
              },
            },
            productItems: {
              text: "Product Items",
              hidden: true,
              options: {
                labels: true,
                labelItems(value) {
                  return `${value.quantity || 1} x ${value.name || value.code}`;
                },
              },
            },
            notes: {
              text: "Notes",
              options: {
                labels: true,
                labelItems(value) {
                  return `${value.username || ""}: ${value.content || ""}`;
                },
              },
            },
            processTime: {
              text: "Process Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            exportTime: {
              text: "Export Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            cancelTime: {
              text: "Cancel Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            processUser: {
              text: "Process User",
              hidden: true,
              options: {
                label: true,
                subProp: "processUser.email",
                sortBy: "processUserId",
              },
            },
            exportUser: {
              text: "Export User",
              hidden: true,
              options: {
                label: true,
                subProp: "exportUser.email",
                sortBy: "exportUserId",
              },
            },
            completeUser: {
              text: "Complete User",
              hidden: true,
              options: {
                label: true,
                subProp: "completeUser.email",
                sortBy: "completeUserId",
              },
            },
            cancelUser: {
              text: "Cancel User",
              hidden: true,
              options: {
                label: true,
                subProp: "cancelUser.email",
                sortBy: "cancelUserId",
              },
            },
            price: userManager.checkRole(["admin"]) && {
              text: "Price",
              hidden: true,
            },
            shippingCost: userManager.checkRole(["admin"]) && {
              text: "Shipping Cost",
              hidden: true,
            },
            discount: userManager.checkRole(["admin"]) && {
              text: "Discount",
              hidden: true,
            },
            tax: userManager.checkRole(["admin"]) && {
              text: "Tax",
              hidden: true,
            },
            finalPrice: userManager.checkRole(["admin"]) && {
              text: "Final Price",
              hidden: true,
            },
            paymentType: {
              text: "Payment Type",
              hidden: true,
            },
            paymentStatus: {
              text: "Payment Status",
              hidden: true,
              options: {
                label(value) {
                  return paymentStatuses[value || ""] || value;
                },
              },
            },
            shipVendorType: {
              text: "Ship Vendor Type",
              hidden: true,
              options: {
                label(value) {
                  return shipVendorTypes[value || ""] || value;
                },
              },
            },
            customerIp: {
              text: "Customer IP",
              hidden: true,
            },
            customerPhone: {
              text: "Customer Phone",
              options: {
                subProp: "customerAddress.phone",
                hidden: true,
              },
            },
            customerEmail: {
              text: "Customer Email",
              options: {
                subProp: "customerAddress.email",
              },
            },
            customerFirstName: {
              text: "Customer First Name",
              options: {
                subProp: "customerAddress.firstName",
              },
            },
            customerLastName: {
              text: "Customer Last Name",
              options: {
                subProp: "customerAddress.lastName",
              },
            },
            customerAddress: {
              text: "Customer Address",
              options: {
                subProp: "customerAddress.address",
              },
            },
            customerAddress2: {
              text: "Customer Address 2",
              options: {
                subProp: "customerAddress.address2",
              },
            },
            customerAddress3: {
              text: "Customer Address 3",
              hidden: true,
              options: {
                subProp: "customerAddress.address3",
              },
            },
            customerAddress4: {
              text: "Customer Address 4",
              hidden: true,
              options: {
                subProp: "customerAddress.address4",
              },
            },
            customerCity: {
              text: "Customer City",
              hidden: true,
              options: {
                subProp: "customerAddress.city",
              },
            },
            customerState: {
              text: "Customer State",
              options: {
                subProp: "customerAddress.state",
              },
            },
            customerCountry: {
              text: "Customer Country",
              options: {
                subProp: "customerAddress.country",
              },
            },
            customerPostalCode: {
              text: "Customer Postal Code",
              options: {
                subProp: "customerAddress.postalCode",
              },
            },
            customerCompany: {
              text: "Customer Company",
              options: {
                subProp: "customerAddress.company",
              },
            },
            shippingPhone: {
              text: "Shipping Phone",
              hidden: true,
              options: {
                subProp: "shippingAddress.phone",
              },
            },
            shippingEmail: {
              text: "Shipping Email",
              hidden: true,
              options: {
                subProp: "shippingAddress.email",
              },
            },
            shippingFirstName: {
              text: "Shipping First Name",
              hidden: true,
              options: {
                subProp: "shippingAddress.firstName",
              },
            },
            shippingLastName: {
              text: "Shipping Last Name",
              hidden: true,
              options: {
                subProp: "shippingAddress.lastName",
              },
            },
            shippingAddress: {
              text: "Shipping Address",
              hidden: true,
              options: {
                subProp: "shippingAddress.address",
              },
            },
            shippingAddress2: {
              text: "Shipping Address 2",
              hidden: true,
              options: {
                subProp: "shippingAddress.address2",
              },
            },
            shippingAddress3: {
              text: "Shipping Address 3",
              hidden: true,
              options: {
                subProp: "shippingAddress.address3",
              },
            },
            shippingAddress4: {
              text: "Shipping Address 4",
              hidden: true,
              options: {
                subProp: "shippingAddress.address4",
              },
            },
            shippingCity: {
              text: "Shipping City",
              hidden: true,
              options: {
                subProp: "shippingAddress.city",
              },
            },
            shippingState: {
              text: "Shipping State",
              hidden: true,
              options: {
                subProp: "shippingAddress.state",
              },
            },
            shippingCountry: {
              text: "Shipping Country",
              hidden: true,
              options: {
                subProp: "shippingAddress.country",
              },
            },
            shippingPostalCode: {
              text: "Shipping Postal Code",
              hidden: true,
              options: {
                subProp: "shippingAddress.postalCode",
              },
            },
            shippingCompany: {
              text: "Shipping Company",
              hidden: true,
              options: {
                subProp: "shippingAddress.company",
              },
            },
            trackingNumber: {
              text: "Tracking Number",
              options: {
                preTransform(value, item) {
                  return item.trackingNumberAlt || value;
                },
              },
            },
            action: {},
          },
          findAllOptionsFilter(options) {
            if (self.status) {
              options.filters.push({
                key: "status",
                operator: "equal_to",
                value: self.status,
              });
            }
            return options;
          },
          topActionButtons: {
            insert: (!this.status || this.status === "open") && {
              target: {
                dialog: {
                  attrs: {
                    width: "80%",
                  },
                },
              },
            },
            sync: {
              attrs: {
                color: "primary",
                dark: false,
              },
              content: {
                text: "Sync Orders",
                icon: "mdi-sync",
              },
              on: {
                async xClick({ self }) {
                  const dataTable = self.context();
                  self.loading.value = true;
                  await dataTable.execute(
                    async () => {
                      await coreApiClient.call("stores", "syncOrders");
                      await coreApiClient.call("shippingVendors", "syncOrders");
                      return true;
                    },
                    undefined,
                    {
                      disableLoading: true,
                      willRefresh: true,
                    }
                  );
                  self.loading.value = false;
                },
              },
            },
            export: {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Export Excel",
                icon: "mdi-microsoft-excel",
              },
              target: {
                menu: {
                  content: {
                    items: {
                      simple: {
                        content: {
                          text: "Simple",
                        },
                        on: {
                          xClick({ self }) {
                            const dataTable = self.context().context();
                            let findAllOptions = dataTable.getFindAllOptions();
                            if (dataTable.model.value.length) {
                              findAllOptions = {
                                payload: JSON.stringify({
                                  filters: [
                                    {
                                      key: "_id",
                                      operator: "in",
                                      value: dataTable.model.value.map(
                                        (item) => item._id
                                      ),
                                    },
                                  ],
                                  limit: -1,
                                }),
                              };
                            }
                            coreApiClient.download(
                              "orders",
                              `@/export/simple`,
                              findAllOptions
                            );
                          },
                        },
                      },
                      export2: {
                        content: {
                          text: "Advanced",
                        },
                        on: {
                          xClick({ self }) {
                            const dataTable = self.context().context();
                            let findAllOptions = dataTable.getFindAllOptions();
                            if (dataTable.model.value.length) {
                              findAllOptions = {
                                payload: JSON.stringify({
                                  filters: [
                                    {
                                      key: "_id",
                                      operator: "in",
                                      value: dataTable.model.value.map(
                                        (item) => item._id
                                      ),
                                    },
                                  ],
                                  limit: -1,
                                }),
                              };
                            }
                            {
                              const payload = JSON.parse(
                                findAllOptions.payload
                              );
                              payload.extraParams = {
                                withProductItems: true,
                              };
                              findAllOptions.payload = JSON.stringify(payload);
                            }
                            coreApiClient.download(
                              "orders",
                              `@/export/advanced`,
                              findAllOptions
                            );
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            import: {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Import Excel",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Import Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled =
                        new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Import Excel",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel =
                          dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Upload",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "orders",
                                  "import",
                                  {},
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          multiSelectActionButtons: {
            batchUpdateStatusProcessing: this.status === "open" && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Process Orders",
              },
              target: {
                confirm: {
                  content: {
                    content: {
                      html: "Process selected orders? This cannot be undone!",
                    },
                  },
                  on: {
                    async xYes({ self: dialog }) {
                      const yesBtn = dialog.yesBtnContainer.value;
                      const btn = dialog.context();
                      const dataTable = btn.context();
                      const ids = dataTable.model.value.map((item) => item._id);
                      yesBtn.loading.value = true;
                      const result = await dataTable.execute(
                        async () => {
                          return await coreApiClient.call(
                            "orders",
                            "batchUpdateStatus",
                            {
                              status: "processing",
                            },
                            {
                              ids,
                            }
                          );
                        },
                        undefined,
                        {
                          disableLoading: true,
                          willRefresh: true,
                        }
                      );
                      yesBtn.loading.value = false;
                      if (result) {
                        dialog.hide();
                      }
                    },
                  },
                },
              },
            },
            batchUpdateStatusShipping: this.status === "processing" && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Export Orders",
              },
              target: {
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled =
                        new DataContainer(false);
                    },
                  },
                  attrs: {
                    width: "800px",
                  },
                  content: {
                    toolbar: {
                      title: "Export Orders",
                      subTitle(dialog) {
                        const btn = dialog.context();
                        const dataTable = btn.context();
                        const items = dataTable.model.value;
                        return `${items.length} Order(s)`;
                      },
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel =
                          dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  shippingVendorId: {
                                    type: "XAutocomplete",
                                    attrs: {
                                      label: "Shipping Vendor",
                                      "item-value": "_id",
                                      "item-text": "name",
                                      required: true,
                                      xOptions: {
                                        content: {
                                          async itemsLoader() {
                                            const { items } =
                                              await coreApiClient.call(
                                                "shippingVendors",
                                                "findAll",
                                                {
                                                  limit: -1,
                                                }
                                              );
                                            return items;
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Submit",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            const ids = dataTable.model.value.map(
                              (item) => item._id
                            );
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "orders",
                                  "batchUpdateStatus",
                                  {
                                    status: "shipping",
                                  },
                                  {
                                    ids,
                                    shippingVendorId: data.shippingVendorId,
                                  }
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: false,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              result.items.sort(
                                (a, b) =>
                                  -1 *
                                  (a.error || "").localeCompare(b.error || "")
                              );
                              const lines = [];
                              for (const item of result.items) {
                                const parts = [];
                                parts.push(`Order ID: ${item.order.orderId}`);
                                if (item.error) {
                                  parts.push(
                                    `<span class="red--text font-weight-bold">${item.error}</span>`
                                  );
                                } else {
                                  parts.push(
                                    `Tracking Number: ${
                                      item.order.trackingNumberAlt ||
                                      item.order.trackingNumber
                                    }`
                                  );
                                }
                                lines.push(parts.join(", "));
                              }
                              form.success.value = lines.join("<br/>");

                              const successItems = result.items.filter(
                                (item) => !item.error
                              );
                              if (successItems.length) {
                                downloadFile(
                                  coreApiClient.makeDownloadUrl(
                                    "orders",
                                    `@/downloadShippingLabels`,
                                    {
                                      ids: successItems
                                        .map((item) => item.order._id)
                                        .join(","),
                                    }
                                  )
                                );
                                if (
                                  result.extraShippingLabelKeys?.includes(
                                    "documentsDouaniers"
                                  )
                                ) {
                                  downloadFile(
                                    coreApiClient.makeDownloadUrl(
                                      "orders",
                                      `@/downloadExtraShippingLabels`,
                                      {
                                        ids: successItems
                                          .map((item) => item.order._id)
                                          .join(","),
                                        key: "documentsDouaniers",
                                      }
                                    )
                                  );
                                }
                              }
                              if (result.items.length === successItems.length) {
                                dataTable.model.value = [];
                                dataTable.refresh();
                                dialog.hide();
                              }
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            batchUpdateStatusCompleted: this.status === "shipping" && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Complete Orders",
              },
              target: {
                confirm: {
                  content: {
                    content: {
                      html: "Complete selected orders? This cannot be undone!",
                    },
                  },
                  on: {
                    async xYes({ self: dialog }) {
                      const yesBtn = dialog.yesBtnContainer.value;
                      const btn = dialog.context();
                      const dataTable = btn.context();
                      const ids = dataTable.model.value.map((item) => item._id);
                      yesBtn.loading.value = true;
                      const result = await dataTable.execute(
                        async () => {
                          return await coreApiClient.call(
                            "orders",
                            "batchUpdateStatus",
                            {
                              status: "completed",
                            },
                            {
                              ids,
                            }
                          );
                        },
                        undefined,
                        {
                          disableLoading: true,
                          willRefresh: true,
                        }
                      );
                      yesBtn.loading.value = false;
                      if (result) {
                        dialog.hide();
                      }
                    },
                  },
                },
              },
            },
            batchUpdateStatusCancelled: !this.status && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Cancel Orders",
              },
              target: {
                confirm: {
                  content: {
                    content: {
                      html: "Cancel selected orders? This cannot be undone!",
                    },
                  },
                  on: {
                    async xYes({ self: dialog }) {
                      const yesBtn = dialog.yesBtnContainer.value;
                      const btn = dialog.context();
                      const dataTable = btn.context();
                      const ids = dataTable.model.value.map((item) => item._id);
                      yesBtn.loading.value = true;
                      const result = await dataTable.execute(
                        async () => {
                          return await coreApiClient.call(
                            "orders",
                            "batchUpdateStatus",
                            {
                              status: "cancelled",
                            },
                            {
                              ids,
                            }
                          );
                        },
                        undefined,
                        {
                          disableLoading: true,
                          willRefresh: true,
                        }
                      );
                      yesBtn.loading.value = false;
                      if (result) {
                        dialog.hide();
                      }
                    },
                  },
                },
              },
            },
            batchDownloadShippingLabel: ["shipping", "completed"].includes(
              this.status
            ) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                icon: "mdi-download",
                text: "Shipping Labels",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  downloadFile(
                    coreApiClient.makeDownloadUrl(
                      "orders",
                      `@/downloadShippingLabels`,
                      {
                        ids: dataTable.model.value
                          .map((item) => item._id)
                          .join(","),
                      }
                    )
                  );
                },
              },
            },
            batchDownloadDocumentsDouaniers: ["shipping", "completed"].includes(
              this.status
            ) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                icon: "mdi-application-import",
                text: "Documents Douaniers",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  downloadFile(
                    coreApiClient.makeDownloadUrl(
                      "orders",
                      `@/downloadExtraShippingLabels`,
                      {
                        ids: dataTable.model.value
                          .map((item) => item._id)
                          .join(","),
                        key: "documentsDouaniers",
                      }
                    )
                  );
                },
              },
              ext: {
                condition(self) {
                  return self.model.value.some((item) =>
                    item.extraShippingLabelKeys?.includes("documentsDouaniers")
                  );
                },
              },
            },
            batchDownloadGroupShippingLabel: ["shipping"].includes(
              this.status
            ) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                icon: "mdi-truck-delivery",
                text: "Group Shipping Label",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  downloadFile(
                    coreApiClient.makeDownloadUrl(
                      "orders",
                      `@/downloadGroupShippingLabel`,
                      {
                        ids: dataTable.model.value
                          .map((item) => item._id)
                          .join(","),
                      }
                    )
                  );
                },
                ext: {
                  condition(self) {
                    return self.model.value.some(
                      (item) => !item.extraShippingLabelKeys?.includes("group")
                    );
                  },
                },
              },
            },
            recalculateInkColor: {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Recalculate Ink Color",
                icon: "mdi-format-color-fill",
              },
              on: {
                async xClick({ self }) {
                  const dataTable = self.context();
                  const ids = dataTable.model.value.map((item) => item._id);
                  self.loading.value = true;
                  await dataTable.execute(
                    async () => {
                      await coreApiClient.call(
                        "orderFulfillment",
                        "recalculateInkColor",
                        undefined,
                        {
                          ids,
                        }
                      );
                      return true;
                    },
                    undefined,
                    {
                      disableLoading: true,
                      willRefresh: true,
                    }
                  );
                  self.loading.value = false;
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: {
                target: {
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    ext: {
                      subTitleMaker: "orderId",
                    },
                  },
                },
              },
              delete: {},
              viewDetails: {
                content: {
                  icon: "mdi-view-list-outline",
                },
                target: {
                  tooltip: { content: { text: "View Details" } },
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    content: {
                      autofocus: false,
                      toolbar: {
                        title: "Order Details",
                        subTitle(self) {
                          const btn = self.context();
                          const { item } = btn.context();
                          return item.orderId;
                        },
                      },
                      content: {
                        type: ProductItemsTable,
                        attrs: {},
                        makeAttrs(attrs: any = {}, dialog) {
                          const { item } = dialog.context().context();
                          attrs.item = item;
                          return attrs;
                        },
                      },
                      buttons: {
                        cancel: {
                          content: {
                            text: "Close",
                          },
                        },
                      },
                    },
                  },
                },
              },
              downloadShippingLabel: {
                content: {
                  icon: "mdi-download",
                },
                on: {
                  xClick({ self }) {
                    const { item } = self.context();
                    downloadFile(
                      coreApiClient.makeDownloadUrl(
                        "orders",
                        `@/downloadShippingLabel/${item._id}`
                      )
                    );
                  },
                },
                target: {
                  tooltip: { content: { text: "Download Shipping Label" } },
                },
                ext: {
                  condition(_, item) {
                    return ["shipping", "completed"].includes(item.status);
                  },
                },
              },
              downloadDocumentsDouaniers: {
                content: {
                  icon: "mdi-application-import",
                },
                on: {
                  xClick({ self }) {
                    const { item } = self.context();
                    downloadFile(
                      coreApiClient.makeDownloadUrl(
                        "orders",
                        `@/downloadExtraShippingLabel/${item._id}/documentsDouaniers`
                      )
                    );
                  },
                },
                target: {
                  tooltip: {
                    content: { text: "Download Documents Douaniers" },
                  },
                },
                ext: {
                  condition(_, item) {
                    return (
                      ["shipping", "completed"].includes(item.status) &&
                      item.extraShippingLabelKeys?.includes(
                        "documentsDouaniers"
                      )
                    );
                  },
                },
              },
              downloadGroupShippingLabel: {
                content: {
                  icon: "mdi-truck-delivery",
                },
                on: {
                  xClick({ self }) {
                    const { item } = self.context();
                    downloadFile(
                      coreApiClient.makeDownloadUrl(
                        "orders",
                        `@/downloadExtraShippingLabel/${item._id}/group`
                      )
                    );
                  },
                },
                target: {
                  tooltip: {
                    content: { text: "Download Group Shipping Label" },
                  },
                },
                ext: {
                  condition(_, item) {
                    return (
                      ["shipping", "completed"].includes(item.status) &&
                      item.extraShippingLabelKeys?.includes("group")
                    );
                  },
                },
              },
              openTrackingUrl: {
                content: {
                  icon: "mdi-open-in-new",
                },
                on: {
                  xClick({ self }) {
                    const { item } = self.context();
                    openInNewTab(
                      coreApiClient.makeDownloadUrl(
                        "orders",
                        `@/trackingUrl/${item._id}`
                      )
                    );
                  },
                },
                target: {
                  tooltip: { content: { text: "Open Tracking URL" } },
                },
                ext: {
                  condition(_, item) {
                    return ["shipping", "completed"].includes(item.status);
                  },
                },
              },
              clone: {
                content: {
                  icon: "mdi-content-duplicate",
                },
                target: {
                  tooltip: { content: { text: "Clone" } },
                  confirm: {
                    content: {
                      content: {
                        html: "Clone this order?",
                      },
                    },
                    on: {
                      async xYes({ self }) {
                        const { item, dataTable } = self.context().context();
                        self.yesBtnContainer.value.loading.value = true;
                        try {
                          const result = await dataTable.execute(
                            async () => {
                              return await coreApiClient.call(
                                "orders",
                                "clone",
                                {
                                  id: item._id,
                                }
                              );
                            },
                            undefined,
                            {
                              disableLoading: true,
                              willRefresh: true,
                            }
                          );
                          if (result) {
                            self.hide();
                          }
                        } finally {
                          self.yesBtnContainer.value.loading.value = false;
                        }
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call(
                "orders",
                "findAll",
                options
              );
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call(
                "orders",
                "create",
                undefined,
                item
              );
            },
            async update(item) {
              return await coreApiClient.call(
                "orders",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("orders", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              description: {
                type: "textLarge",
                attrs: {
                  label: "Description",
                },
              },
              sourceType: {
                type: "select",
                attrs: {
                  label: "Source Type",
                  items: makeSelectOptions(sourceTypes),
                  required: true,
                },
              },
              originSourceId: {
                type: "text",
                attrs: {
                  label: "Origin Source ID",
                  required: true,
                },
              },
              sourceTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Source Time",
                  required: true,
                },
              },
              status: {
                type: "select",
                attrs: {
                  label: "Status",
                  items: makeSelectOptions(statuses),
                  required: true,
                },
              },
              processTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Process Time",
                },
              },
              exportTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Export Time",
                },
              },
              completeTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Complete Time",
                },
              },
              cancelTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Cancel Time",
                },
              },
              processUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Process User",
                  "item-value": "_id",
                  "item-text": "email",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "users",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "in",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
              },
              exportUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Export User",
                  "item-value": "_id",
                  "item-text": "email",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "users",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "in",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
              },
              completeUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Complete User",
                  "item-value": "_id",
                  "item-text": "email",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "users",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "in",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
              },
              cancelUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Cancel User",
                  "item-value": "_id",
                  "item-text": "email",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "users",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "in",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
              },
              storeId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Store",
                  "item-value": "_id",
                  "item-text": "name",
                  required: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "stores",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "in",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
              },
              price: {
                type: "number",
                attrs: {
                  label: "Price",
                },
                ext: {
                  condition() {
                    return userManager.checkRole(["admin"]);
                  },
                },
              },
              shippingCost: {
                type: "number",
                attrs: {
                  label: "Shipping Cost",
                },
                ext: {
                  condition() {
                    return userManager.checkRole(["admin"]);
                  },
                },
              },
              discount: {
                type: "number",
                attrs: {
                  label: "Discount",
                },
                ext: {
                  condition() {
                    return userManager.checkRole(["admin"]);
                  },
                },
              },
              tax: {
                type: "number",
                attrs: {
                  label: "Tax",
                },
                ext: {
                  condition() {
                    return userManager.checkRole(["admin"]);
                  },
                },
              },
              finalPrice: {
                type: "number",
                attrs: {
                  label: "Final Price",
                },
                ext: {
                  condition() {
                    return userManager.checkRole(["admin"]);
                  },
                },
              },
              paymentType: {
                type: "text",
                attrs: {
                  label: "Payment Type",
                },
              },
              paymentStatus: {
                type: "select",
                attrs: {
                  label: "Payment Status",
                  items: makeSelectOptions(paymentStatuses),
                },
              },
              shipVendorType: {
                type: "select",
                attrs: {
                  label: "Ship Vendor Type",
                  items: makeSelectOptions(shipVendorTypes),
                },
              },
              customerIp: {
                type: "text",
                attrs: {
                  label: "Customer IP",
                },
              },
              "customerAddress.phone": {
                type: "text",
                attrs: {
                  label: "Customer Phone",
                },
              },
              "customerAddress.email": {
                type: "text",
                attrs: {
                  label: "Customer Email",
                },
              },
              "customerAddress.firstName": {
                type: "text",
                attrs: {
                  label: "Customer First Name",
                },
              },
              "customerAddress.lastName": {
                type: "text",
                attrs: {
                  label: "Customer Last Name",
                },
              },
              "customerAddress.address": {
                type: "text",
                attrs: {
                  label: "Customer Address",
                },
              },
              "customerAddress.address2": {
                type: "text",
                attrs: {
                  label: "Customer Address 2",
                },
              },
              "customerAddress.address3": {
                type: "text",
                attrs: {
                  label: "Customer Address 3",
                },
              },
              "customerAddress.address4": {
                type: "text",
                attrs: {
                  label: "Customer Address 4",
                },
              },
              "customerAddress.city": {
                type: "text",
                attrs: {
                  label: "Customer City",
                },
              },
              "customerAddress.state": {
                type: "text",
                attrs: {
                  label: "Customer State",
                },
              },
              "customerAddress.country": {
                type: "text",
                attrs: {
                  label: "Customer Country",
                },
              },
              "customerAddress.postalCode": {
                type: "text",
                attrs: {
                  label: "Customer Postal Code",
                },
              },
              "customerAddress.company": {
                type: "text",
                attrs: {
                  label: "Customer Company",
                },
              },
              "shippingAddress.phone": {
                type: "text",
                attrs: {
                  label: "Shipping Phone",
                },
              },
              "shippingAddress.email": {
                type: "text",
                attrs: {
                  label: "Shipping Email",
                },
              },
              "shippingAddress.firstName": {
                type: "text",
                attrs: {
                  label: "Shipping First Name",
                },
              },
              "shippingAddress.lastName": {
                type: "text",
                attrs: {
                  label: "Shipping Last Name",
                },
              },
              "shippingAddress.address": {
                type: "text",
                attrs: {
                  label: "Shipping Address",
                },
              },
              "shippingAddress.address2": {
                type: "text",
                attrs: {
                  label: "Shipping Address 2",
                },
              },
              "shippingAddress.address3": {
                type: "text",
                attrs: {
                  label: "Shipping Address 3",
                },
              },
              "shippingAddress.address4": {
                type: "text",
                attrs: {
                  label: "Shipping Address 4",
                },
              },
              "shippingAddress.city": {
                type: "text",
                attrs: {
                  label: "Shipping City",
                },
              },
              "shippingAddress.state": {
                type: "text",
                attrs: {
                  label: "Shipping State",
                },
              },
              "shippingAddress.country": {
                type: "text",
                attrs: {
                  label: "Shipping Country",
                },
              },
              "shippingAddress.postalCode": {
                type: "text",
                attrs: {
                  label: "Shipping Postal Code",
                },
              },
              "shippingAddress.company": {
                type: "text",
                attrs: {
                  label: "Shipping Company",
                },
              },
              shippingStatus: {
                type: "select",
                attrs: {
                  label: "Shipping Status",
                  items: makeSelectOptions(shippingStatuses),
                },
              },
              productItems: {
                type: "XArrayInput",
                attrs: {
                  xOptions: {
                    content: {
                      removeMethod: "setDeleted",
                      itemLabel: (item) =>
                        `${item.quantity || 1} x ${item.code || "N/A"}`,
                      itemProperties: {
                        quantity: {
                          type: "number",
                          attrs: {
                            label: "Quantity",
                            required: true,
                            readonly: true,
                          },
                        },
                        code: {
                          type: "text",
                          attrs: {
                            label: "Code",
                          },
                        },
                        id: {
                          type: "text",
                          attrs: {
                            label: "ID",
                          },
                        },
                        price: {
                          type: "number",
                          attrs: {
                            label: "Price",
                          },
                          ext: {
                            condition() {
                              return userManager.checkRole(["admin"]);
                            },
                          },
                        },
                        weight: {
                          type: "number",
                          attrs: {
                            label: "Weight",
                          },
                        },
                        deliveryType: {
                          type: "text",
                          attrs: {
                            label: "Delivery Type",
                          },
                        },
                        categoryCode: {
                          type: "text",
                          attrs: {
                            label: "Category Code",
                          },
                        },
                        productVariantId: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Product Variant",
                            "item-value": "_id",
                            "item-text": "name",
                            required: true,
                            xOptions: {
                              content: {
                                async itemsSuggestor(search, value, limit) {
                                  const { items } = await coreApiClient.call(
                                    "productVariants",
                                    "findAll",
                                    {
                                      payload: JSON.stringify({
                                        limit,
                                        search,
                                        filters: [
                                          {
                                            key: "_id",
                                            operator: "equal_to",
                                            value,
                                          },
                                        ],
                                      }),
                                    }
                                  );
                                  return items;
                                },
                              },
                            },
                          },
                        },
                        fulfillmentStatus: {
                          type: "select",
                          attrs: {
                            label: "Fulfillment Status",
                            items: makeSelectOptions(fulfillmentStatuses),
                          },
                        },
                        fulfillmentQualityCheckFailed: {
                          type: "boolean",
                          attrs: {
                            label: "Fulfillment Quality Check Failed",
                          },
                        },
                        fulfillmentQualityCheckFailCount: {
                          type: "integer",
                          attrs: {
                            label: "Fulfillment Quality Check Fail Count",
                          },
                        },
                        fulfillmentPackagedQuantityIndexes: {
                          type: "textMultiple",
                          attrs: {
                            label: "Fulfillment Packaged Indexes",
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 3,
                            ignoreOtherFields: false,
                          },
                        },
                      },
                    },
                  },
                },
                ext: {
                  colLength: 12,
                },
              },
              notes: {
                type: "XArrayInput",
                attrs: {
                  xOptions: {
                    content: {
                      itemLabel: (item) =>
                        `${item.username || "N/A"}: ${item.content || "N/A"}`,
                      itemProperties: {
                        time: {
                          type: "XDateTimePicker",
                          attrs: {
                            label: "Time",
                          },
                        },
                        username: {
                          type: "text",
                          attrs: {
                            label: "Username",
                          },
                        },
                        content: {
                          type: "text",
                          attrs: {
                            label: "Content",
                            required: true,
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 4,
                            ignoreOtherFields: false,
                          },
                        },
                      },
                    },
                  },
                },
                ext: {
                  colLength: 12,
                },
              },
            },
            insertForm: {
              content: {
                layout: "tabs",
                sections: {
                  genenal: {
                    header: "General Info",
                    fieldNames: [
                      "description",
                      "storeId",
                      "sourceType",
                      "originSourceId",
                      "sourceTime",
                      "status",
                      "shipVendorType",
                      "customerIp",
                    ],
                  },
                  processInfo: {
                    header: "Process Info",
                    fieldNames: [
                      "processTime",
                      "exportTime",
                      "completeTime",
                      "cancelTime",
                      "processUserId",
                      "exportUserId",
                      "completeUserId",
                      "cancelUserId",
                    ],
                  },
                  productItems: {
                    header: "Product Items",
                    fieldNames: ["productItems"],
                  },
                  notes: {
                    header: "Notes",
                    fieldNames: ["notes"],
                  },
                  finance: {
                    header: "Finance",
                    fieldNames: [
                      "price",
                      "shippingCost",
                      "discount",
                      "tax",
                      "finalPrice",
                    ],
                  },
                  payment: {
                    header: "Payment",
                    fieldNames: ["paymentType", "paymentStatus"],
                  },
                  customerAddress: {
                    header: "Customer Address",
                    fieldNames: [
                      "customerAddress.phone",
                      "customerAddress.email",
                      "customerAddress.firstName",
                      "customerAddress.lastName",
                      "customerAddress.address",
                      "customerAddress.address2",
                      "customerAddress.address3",
                      "customerAddress.address4",
                      "customerAddress.city",
                      "customerAddress.state",
                      "customerAddress.country",
                      "customerAddress.postalCode",
                      "customerAddress.company",
                    ],
                  },
                  shippingAddress: {
                    header: "Shipping Address",
                    fieldNames: [
                      "shippingAddress.phone",
                      "shippingAddress.email",
                      "shippingAddress.firstName",
                      "shippingAddress.lastName",
                      "shippingAddress.address",
                      "shippingAddress.address2",
                      "shippingAddress.address3",
                      "shippingAddress.address4",
                      "shippingAddress.city",
                      "shippingAddress.state",
                      "shippingAddress.country",
                      "shippingAddress.postalCode",
                      "shippingAddress.company",
                    ],
                  },
                  shippingStatus: {
                    header: "Shipping Status",
                    fieldNames: [
                      "trackingNumber",
                      "trackingNumberAlt",
                      "shippingStatus",
                    ],
                  },
                },
                colLength: 3,
              },
            },
            editForm: {
              content: {
                layout: "tabs",
                sections: {
                  genenal: {
                    header: "General Info",
                    fieldNames: [
                      "description",
                      "storeId",
                      "sourceType",
                      "originSourceId",
                      "sourceTime",
                      "status",
                      "shipVendorType",
                      "customerIp",
                    ],
                  },
                  processInfo: {
                    header: "Process Info",
                    fieldNames: [
                      "processTime",
                      "exportTime",
                      "completeTime",
                      "cancelTime",
                      "processUserId",
                      "exportUserId",
                      "completeUserId",
                      "cancelUserId",
                    ],
                  },
                  productItems: {
                    header: "Product Items",
                    fieldNames: ["productItems"],
                  },
                  notes: {
                    header: "Notes",
                    fieldNames: ["notes"],
                  },
                  finance: {
                    header: "Finance",
                    fieldNames: [
                      "price",
                      "shippingCost",
                      "discount",
                      "tax",
                      "finalPrice",
                    ],
                  },
                  payment: {
                    header: "Payment",
                    fieldNames: ["paymentType", "paymentStatus"],
                  },
                  customerAddress: {
                    header: "Customer Address",
                    fieldNames: [
                      "customerAddress.phone",
                      "customerAddress.email",
                      "customerAddress.firstName",
                      "customerAddress.lastName",
                      "customerAddress.address",
                      "customerAddress.address2",
                      "customerAddress.address3",
                      "customerAddress.address4",
                      "customerAddress.city",
                      "customerAddress.state",
                      "customerAddress.country",
                      "customerAddress.postalCode",
                      "customerAddress.company",
                    ],
                  },
                  shippingAddress: {
                    header: "Shipping Address",
                    fieldNames: [
                      "shippingAddress.phone",
                      "shippingAddress.email",
                      "shippingAddress.firstName",
                      "shippingAddress.lastName",
                      "shippingAddress.address",
                      "shippingAddress.address2",
                      "shippingAddress.address3",
                      "shippingAddress.address4",
                      "shippingAddress.city",
                      "shippingAddress.state",
                      "shippingAddress.country",
                      "shippingAddress.postalCode",
                      "shippingAddress.company",
                    ],
                  },
                  shippingStatus: {
                    header: "Shipping Status",
                    fieldNames: [
                      "trackingNumber",
                      "trackingNumberAlt",
                      "shippingStatus",
                    ],
                  },
                },
                colLength: 3,
              },
            },
            delete: {
              confirmDisplayField: "orderId",
            },
          },
        },
      },
    };
  },
  methods: {
    downloadVariationListItem(item, index, name) {
      downloadFile(
        coreApiClient.makeDownloadUrl(
          `orders/@/downloadVariationListItem/${item._id}/${index}/${name}`
        )
      );
    },
  },
});
